.auth-page {
    /* margin-top: 8rem; */
    min-height: 90vh;
    background-image: linear-gradient(-225deg, #9795f0 0%, #fbc8d4 100%);
}

.login-form {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(245, 245, 245, 0.4));
    /* backdrop-filter: blur(50px);  */
}

.login-logo {
    height: auto;
    width: 30%;
}


.login-form-heading {
    color: #174873;
}

.two-factor-form-heading,
.forgot-password-form-heading {
    font-family: 'Montserrat', sans-serif;
    color: #174873;
}

.auth-page ::placeholder {
    color: rgba(143, 137, 137, 0.863) !important;
}

::-webkit-input-placeholder {
    color: #FF0000;
    opacity: 1 !important;
    /* for older chrome versions. may no longer apply. */
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #FF0000;
    opacity: 1 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #FF0000;
    opacity: 1 !important;
}

:-ms-input-placeholder {
    color: #FF0000;
}