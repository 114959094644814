* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.background-sheet {
  background-color: #DCD9D4;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), radial-gradient(at 50% 0%, rgba(255, 255, 255, 0.10) 0%, rgba(0, 0, 0, 0.50) 50%);
  background-blend-mode: soft-light, screen;
}

h5,
h4 {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
}

body {
  height: 100%;
}

button:focus {
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*=======================================---
 */
.Action_icon {
  color: grey;
  margin: 0 .3em
}

.Action_icon:hover {
  color: #000;
}

/* ---------------------- */

/* SideBar + Main content height */
.mainContentArea {
  min-height: 100vh;
}

/* Main content height when sidebar Open */
.widthWhenSidebarOpen {
  transition: .2s ease-in-out;
  width: calc(100% - 15em);
  padding: 0 .5em;
  overflow-x: hidden;
}

/* Main content height when sidebar closed */
.widthWhenSidebarClose {
  transition: .2s ease-in-out;
  width: calc(100% - 4.5em);
  padding: 0 .5em;
  overflow-x: hidden;
}

/* ---------------------- */
.nav_tab {
  color: black;
  padding-bottom: .5rem;
  font-weight: 500;
  letter-spacing: .5px;
}

.nav_tab:hover {
  color: #3282b8;
}

.nav_tab.active {
  border-bottom: 2px solid #3282b8;
}

/*======================================= */

.Breadcrumb {
  background-color: #f8f9fa;
  margin-top: .5rem;
  padding: 0 0 .1rem 1rem;
}

.Breadcrumb strong {
  font-weight: 500;
}

/*======================================= */

#mtable_Row {
  background-color: #fff !important;
  transition: .2ms !important;
  /* font-size:.8em; */
}

#mtable_Row:hover td {
  background-color: #f8f9fa !important;
  color: #3282b8 !important;
  -webkit-box-shadow: 2px 2px 2px 0px !important;
  -moz-box-shadow: 2px 2px 2px 0px !important;
  box-shadow: 2px 2px 2px 0px !important;
}

/* ================= below Class to display red Astrisk in front of input field label========= */

.red-asterisk::after {
  content: "*";
  color: red;
  margin-left: .5em;
  font-weight: bold;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: rgba(0, 0, 0, 0.788);
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  /* Spacing */
  --rmsc-radius: 4px;
  /* Radius */
  --rmsc-h: 38px;
  /* Height */
}

/* ================== Rich text Editor Custom CSS ================= */

.editor-class {
  background-color: whitesmoke;
  padding: 0rem 1rem;
  max-height: 20rem;

}

.toolbar-class {
  background-color: #EFEFEF;
  color: #000;
}

.toolbar-class a {
  text-decoration: none;
}

.demo-option-custom {
  padding: .8rem
}

.demo-popup-custom {
  padding: .5rem;
}

/* ===== Embed Popup CSS  */
.rdw-embedded-modal-header-option {
  width: 100%;
}

.rdw-embedded-modal-header-label {
  width: 100%;
  margin-bottom: .5rem;
}

.rdw-embedded-modal-link-input {
  width: 100%;
}



/* -------------------------------------------Media Query--------------------------------------- */

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
  .widthWhenSidebarOpen {
    min-width: 100%;
  }

  .widthWhenSidebarClose {
    min-width: 100%;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}